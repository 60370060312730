import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, timeout, concatMap } from 'rxjs/operators';
import { DataKlinik } from '../models/data-klinik.model';
import { StorageService } from './storage/storage.service';
import { storageDict } from './storage/storage-dict';
import { AuthService } from './auth.service';
import { DetailNavigasiDataService } from './data/detail-navigasi.data.service';
import { DataKlinikService } from './data/data-klinik.data.service';
import { BuildForm } from 'app/models/formulir/build-form.model';
import { BuildFormDataService } from './data/formulir/build-form.data.service';
import { ClinicEnvironmentService } from './clinic-environment.service';
import { sidebarStaticMenuCustomAdmin } from 'app/configs/application-menu-admin-custom';
import { superadminItems } from 'app/configs/application-menu';
import ValueGuard from './utils/value-guard';
import { SettingAuthSatuSehatDataService } from './data/settings/setting-auth-satusehat.data.service';

export interface SidebarMenuChild {
  title: string;
  url?: string;
  icon?: string;
  condition?: boolean;
  clinicType?: string[];
  clinicId?: number[];
  children?: SidebarMenuChild[];
}

export interface SidebarMenu {
  title: string;
  icon?: string;
  condition?: boolean;
  /** To regularize our sidebar, children must be present on parent menu,
   * otherwise the menu entry won't be shown. */
  children?: SidebarMenuChild[];
}

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public menu$ = new BehaviorSubject<SidebarMenu[]>([]);

  public loaded = false;

  public sideBar: any[] = [];
  private dataKlinik: DataKlinik;

  private authSubscribed = false;

  constructor(
    private authService: AuthService,
    private dataKlinikService: DataKlinikService,
    private detailNavigasiDataService: DetailNavigasiDataService,
    private storage: StorageService,
    private buildFormDataService: BuildFormDataService,
    private settingAuthSatuSehatDataService: SettingAuthSatuSehatDataService,
    private storageService: StorageService,
    private clinicEnv: ClinicEnvironmentService
  ) {}

  /**
   * These menu are for superadmin only, use menus from 'navigation' table for other karyawan.
   * Staff menu initialized inside `loadStaffMenu()` function.
   * */
  private loadSuperadminMenu() {
    const clinicId = this.dataKlinik.id;
    const typePrice = this.dataKlinik.typePrice?.toLowerCase().trim() || 'rs';
    const filtered = superadminItems
      .map((parent: SidebarMenu) => {
        const fChildren = parent.children.filter((child: SidebarMenuChild) => {
          let isClinicInChildType: boolean;
          const exclusionPackages = ['starter', 'basic', 'premium', 'business', 'businessplus'];
          if (exclusionPackages.includes(typePrice)) {
            isClinicInChildType = true;
          } else {
            isClinicInChildType = child.clinicType.some((childType) => childType === typePrice);
          }
          const isClinicSameId = !child.clinicId ? true : child.clinicId.some((childId) => childId === clinicId);
          return isClinicInChildType && isClinicSameId;
        });
        return {
          ...parent,
          children: fChildren,
        };
      })
      .filter((parent: SidebarMenu) => parent.children.length > 0);

    this.sideBar = [...filtered];
    this.menu$.next(this.sideBar);
  }

  private async loadCustomAdminMenu() {
    const customAdminMenu = sidebarStaticMenuCustomAdmin.filter((item) => item.children.length > 0);
    await this.loadStaffMenu(customAdminMenu);
  }

  private async loadStaffMenu(initialGroup?: SidebarMenu[]) {
    let items: SidebarMenu[] = [];
    const typePrice = this.dataKlinik.typePrice?.toLowerCase().trim() || 'rs';
    const idLogin = await this.storage.getItem(storageDict.idLogin).toPromise();
    const tempItems = await this.detailNavigasiDataService
      .getByLogin(idLogin)
      .pipe
      // tap((res) => console.log('awal', res)),
      ()
      .toPromise();

    const authSatusehat: { dateMake: string } = await this.settingAuthSatuSehatDataService
      .getAll()
      .pipe(
        timeout(15000),
        catchError(() => of(null))
      )
      .toPromise();

    const navs = tempItems.map((navItem) => navItem.referenceNavigasi);
    const navHeaders = Array.from(new Set(navs.map((res) => res.navigasi.id))).map((id) => {
      return navs.find((s) => s.navigasi.id === id).navigasi;
    });

    navHeaders.map((header) => {
      const navChildren = navs.filter((nav) => nav.navigasi.id === header.id);
      if (navChildren.every((item) => item.url === null || item.url.length < 1)) {
        return;
      }
      const availableChildren = navChildren.filter((item) => item.url !== null);
      items.push({
        title: header.nama,
        icon: `assets/icon/navigation/${header.icon}`,
        children: availableChildren.map((item) => {
          return {
            title: item.nama,
            url: item.url === '/pos' ? '/pos-v2' : item.url,
            icon: item.icon,
          };
        }),
      });
    });
    items.push(
      {
        title: 'Manage Permohonan Cuti',
        icon: `assets/icon/navigation/e-office-icon.svg`,
        children: [
          {
            title: `Manage Permohonan Cuti`,
            url: '/permohonan-cuti',
            icon: `assets/icon/navigation/e-office-icon.svg`,
          },
        ],
      },
      {
        title: 'Reimbursement',
        icon: `assets/icon/navigation/reimbursement.svg`,
        children: [
          {
            title: `Manage Reimbursement`,
            icon: '',
            url: '/reimbursement',
            clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
          },
          {
            title: 'Manage Approval Reimburse Pegawai',
            url: '/approval-permohonan-reimburse',
            icon: '',
            clinicType: ['klinik', 'rs', 'rsp', 'partner', 'rscustomrsch'],
          },
        ],
      }
    );

    if (authSatusehat) {
      items.push({
        title: 'SATUSEHAT',
        icon: `assets/icon/navigation/integrasi-icon.svg`,
        children: [
          {
            title: `Log SATUSEHAT Kemenkes`,
            url: '/manage-log-satusehat',
          },
        ],
      });
    }

    this.sideBar = [...items];
    if (ValueGuard.againtsNullOrUndefined(initialGroup)) {
      this.sideBar = [...this.sideBar, ...initialGroup];
    }
    this.menu$.next(this.sideBar);
  }

  private async loadBuildForms() {
    // console.log('test');
    let forms = await this.buildFormDataService
      .getAll()
      .pipe(
        timeout(15000),
        catchError((err) => throwError(err))
      )
      .toPromise();
    forms = forms
      .filter((item) => !!item.namaTable)
      .map((item) => {
        return {
          ...item,
          type: item.type ? item.type : 'Lainnya',
        };
      });

    if (forms.length > 0) {
      let activeSidebar: SidebarMenu[] = [];
      const uniqueTypes = [...new Set(forms.map((val) => val.type))];

      uniqueTypes.map((type) => {
        const grouped = forms.filter((item) => item.type === type);

        activeSidebar.push({
          title: type,
          icon: './assets/icon/navigation/sarana-prasarana.svg',
          children: grouped.map((item) => {
            return {
              title: item.namaForm,
              url: `/formulir/akreditas/${item.id}`,
            };
          }),
        });
      });

      this.sideBar = [...this.sideBar, ...activeSidebar];
      this.menu$.next(this.sideBar);
    }
  }

  private async loadMenu() {
    const idKategoriKaryawan = await this.storage.getItem(storageDict.idKategoriKaryawan).toPromise();
    const dataKlinik = await this.storage.getItem(storageDict.klinik).toPromise();
    if (idKategoriKaryawan && idKategoriKaryawan === 1 && dataKlinik['typePrice'] !== 'free') {
      // Superadmin
      this.loadSuperadminMenu();
    } else if (idKategoriKaryawan === 31) {
      // Custom Admin
      await this.loadCustomAdminMenu();
    } else {
      this.loadStaffMenu();
    }
    if (this.loaded === false) {
      this.loadBuildForms();
      this.loaded = true;
    }
  }

  public async initializeMenu() {
    await this.dataKlinikService
      .getAllV2()
      .pipe(
        tap((res) => {
          // console.log('initMenu', res);
          this.dataKlinik = res;
          this.loadMenu();
        })
      )
      .toPromise();

    if (!this.authSubscribed) {
      this.authService.authState$.subscribe((res) => {
        if (res === true) {
          this.loadMenu();
        } else {
          this.menu$.next([]);
        }
      });
      this.authSubscribed = true;
    }
  }

  public async syncClinic() {
    await this.dataKlinikService
      .getAllV2()
      .pipe(
        tap((res) => {
          // console.log('syncMenu', res);
          this.dataKlinik = res;
          this.loadMenu();
        })
      )
      .toPromise();
  }

  /** Fetch flattened menu with links as an array. */
  public async getAvailableMenus(refetch = false): Promise<any[]> {
    return new Promise(async (resolve) => {
      const result = [];

      const pushResult = (item: SidebarMenu, icon: string) => {
        result.push({
          ...item,
          icon: icon,
        });
      };

      if (refetch) {
        await this.loadMenu();
      }
      this.sideBar.forEach((parent) => {
        if (parent.children && parent.children.length > 0) {
          parent.children.forEach((item) => {
            pushResult(item, parent.icon);
          });
        } else {
          result.push(parent);
        }
      });
      resolve(result);
    });
  }
}
