import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import {
  AlertModalCommonComponent,
  AlertModalOptions,
} from '../components/_ui/_modals/alert-modal-common/alert-modal-common.component';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController, private modalController: ModalController) {}

  async confirmationAlert(
    message: string,
    header: string,
    okText = 'OK',
    cancelText = 'Batal',
    okHandler: any = () => {}
  ): Promise<void> {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: [
        {
          text: okText,
          handler: okHandler,
        },
        {
          text: cancelText,
          role: 'cancel',
        },
      ],
    });

    return await alert.present();
  }

  async okAlert(message: string, header: string, okHandler: any = () => {}, mode?: any): Promise<void> {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: [
        {
          text: 'OK',
          handler: okHandler,
        },
      ],
      mode,
    });

    return await alert.present();
  }

  async settingAlert(message: string, header: string, okHandler: any = () => {}): Promise<void> {
    let okPressed = false;
    const alert = await this.alertController.create({
      message,
      header,
      buttons: [
        {
          text: 'Pengaturan',
          handler: () => {
            okPressed = true;
            okHandler();
          },
        },
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    return await alert.present();
  }

  async okAlertWithDismiss(message: string, header: string, okHandler: any = () => {}): Promise<void> {
    let okPressed = false;
    const alert = await this.alertController.create({
      message,
      header,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            okPressed = true;
            okHandler();
          },
        },
      ],
    });

    alert.onDidDismiss().then(() => {
      if (!okPressed) {
        okHandler();
      }
    });

    return await alert.present();
  }

  async customAlert(message: string, mode: any, header: string, buttons: any[], inputs: any[]): Promise<void> {
    const alert = await this.alertController.create({
      message,
      mode,
      header,
      buttons,
      inputs,
    });

    return await alert.present();
  }
}
