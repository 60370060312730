import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'secure-login-background',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./secure-login-background/secure-login-background.module').then((m) => m.SecureLoginBackgroundPageModule),
  },
  {
    path: 'loginBackground',
    loadChildren: () => import('./login-background/login-background.module').then((m) => m.LoginBackgroundPageModule),
  },
  {
    path: 'pendaftaran-pasien-baru',
    loadChildren: () =>
      import('./medical-record/pendaftaran-pasien-baru/pendaftaran-pasien-baru.module').then(
        (m) => m.PendaftaranPasienBaruPageModule
      ),
  },
  {
    path: 'mini-pendaftaran-pasien-baru',
    loadChildren: () =>
      import('./medical-record/mini-pendaftaran-pasien-baru/mini-pendaftaran-pasien-baru.module').then(
        (m) => m.MiniPendaftaranPasienBaruPageModule
      ),
  },
  {
    path: 'antrien-pasien-poliklinik',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./medis/antrien-pasien-poliklinik/antrien-pasien-poliklinik.module').then(
        (m) => m.AntrienPasienPoliklinikPageModule
      ),
  },
  {
    path: 'rawatjalan/:idInstallasi',
    loadChildren: () => import('./medis/rawatjalan/rawatjalan.module').then((m) => m.RawatjalanPageModule),
  },
  {
    path: 'pasien',
    loadChildren: () => import('./medical-record/pasien/pasien.module').then((m) => m.PasienPageModule),
  },
  {
    path: 'pasien/:keywordPasien',
    loadChildren: () => import('./medical-record/pasien/pasien.module').then((m) => m.PasienPageModule),
  },
  {
    path: 'point-of-sales',
    loadChildren: () => import('./kasir/point-of-sales/point-of-sales.module').then((m) => m.PointOfSalesPageModule),
  },
  {
    path: 'antrian-pasien-rawat-jalan',
    loadChildren: () =>
      import('./kasir/antrian-pasien-rawat-jalan/antrian-pasien-rawat-jalan.module').then(
        (m) => m.AntrianPasienRawatJalanPageModule
      ),
  },
  {
    path: 'pembayaran',
    loadChildren: () =>
      import('./kasir/point-of-sales/pembayaran/pembayaran.module').then((m) => m.PembayaranPageModule),
  },
  {
    path: 'beli-item/:idPasien',
    loadChildren: () => import('./kasir/point-of-sales/beli-item/beli-item.module').then((m) => m.BeliItemPageModule),
  },
  {
    path: 'detail-pasien/:noRM',
    loadChildren: () =>
      import('./medical-record/pasien/detail-pasien/detail-pasien.module').then((m) => m.DetailPasienPageModule),
  },
  {
    path: 'daftar-pasien/:noRekamMedis/:idBuatJanji',
    loadChildren: () =>
      import('./medical-record/pasien/daftar-pasien/daftar-pasien.module').then((m) => m.DaftarPasienPageModule),
  },
  {
    path: 'data-pasien/:idPasien',
    loadChildren: () =>
      import('./medical-record/pasien/data-pasien/data-pasien.module').then((m) => m.DataPasienPageModule),
  },
  {
    path: 'ubah-data-pasien/:noRM',
    loadChildren: () =>
      import('./medical-record/pasien/ubah-data-pasien/ubah-data-pasien.module').then(
        (m) => m.UbahDataPasienPageModule
      ),
  },
  {
    path: 'detail-jadwal-modal',
    loadChildren: () =>
      import('./dashboard/detail-jadwal-modal/detail-jadwal-modal.module').then((m) => m.DetailJadwalModalPageModule),
  },
  {
    path: 'jadwal-dokter',
    loadChildren: () => import('./dashboard/jadwal-dokter/jadwal-dokter.module').then((m) => m.JadwalDokterPageModule),
  },
  {
    path: 'janji-pasien',
    loadChildren: () => import('./dashboard/janji-pasien/janji-pasien.module').then((m) => m.JanjiPasienPageModule),
  },
  {
    path: 'rekam-medis',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/rekam-medis/rekam-medis.module').then((m) => m.RekamMedisPageModule),
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then((m) => m.NotificationPageModule),
  },
  {
    path: 'detail-notification/:id',
    loadChildren: () =>
      import('./notification/detail-notification/detail-notification.module').then(
        (m) => m.DetailNotificationPageModule
      ),
  },
  {
    path: 'obat',
    loadChildren: () => import('./dashboard/obat/obat.module').then((m) => m.ObatPageModule),
  },
  {
    path: 'dashboard-tindakan',
    loadChildren: () => import('./dashboard/tindakan/tindakan.module').then((m) => m.TindakanPageModule),
  },
  {
    path: 'janji-pasien-pribadi',
    loadChildren: () =>
      import('./dashboard/janji-pasien-pribadi/janji-pasien-pribadi.module').then(
        (m) => m.JanjiPasienPribadiPageModule
      ),
  },
  {
    path: 'riwayat-kunjungan/:idLogStatus',
    loadChildren: () =>
      import('./medical-record/pasien/data-pasien/riwayat-kunjungan/riwayat-kunjungan.module').then(
        (m) => m.RiwayatKunjunganPageModule
      ),
  },
  {
    path: 'appointments/:appointmentsType',
    loadChildren: () => import('./appointments/appointments.module').then((m) => m.AppointmentsPageModule),
  },
  {
    path: 'appointments/:appointmentId/appointment-chat-rooms',
    loadChildren: () =>
      import('./appointment-chat-room/appointment-chat-room.module').then((m) => m.AppointmentChatRoomPageModule),
  },
  {
    path: 'detail-kunjungan/:idInstallasi',
    loadChildren: () =>
      import('./medis/rawatjalan/detail-kunjungan/detail-kunjungan.module').then((m) => m.DetailKunjunganPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'video-call/:roomName/:karyawanId',
    loadChildren: () => import('./video-call/video-call.module').then((m) => m.VideoCallPageModule),
  },
  {
    path: 'antrian-pasien-ranap',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/antrian-pasien-ranap.module').then((m) => m.AntrianPasienRanapPageModule),
  },
  {
    path: 'pasien-rawat-inap/:idRuang',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/pasien-rawat-inap.module').then(
        (m) => m.PasienRawatInapPageModule
      ),
  },
  {
    path: 'permintaan-gudang',
    loadChildren: () =>
      import('./logistik/permintaan-gudang/permintaan-gudang.module').then((m) => m.PermintaanGudangPageModule),
  },
  {
    path: 'detail-permintaan-gudang',
    loadChildren: () =>
      import('./logistik/permintaan-gudang/detail-permintaan-gudang/detail-permintaan-gudang.module').then(
        (m) => m.DetailPermintaanGudangPageModule
      ),
  },
  {
    path: 'tracer-pasien',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/tracer-pasien.module').then((m) => m.TracerPasienPageModule),
  },
  {
    path: 'pindah-klinik',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/pindah-klinik/pindah-klinik.module').then((m) => m.PindahKlinikPageModule),
  },
  {
    path: 'ubah-nasabah',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-nasabah/ubah-nasabah.module').then((m) => m.UbahNasabahPageModule),
  },
  {
    path: 'tambah-tracer-pasien',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/tambah-tracer-pasien/tambah-tracer-pasien.module').then(
        (m) => m.TambahTracerPasienPageModule
      ),
  },
  {
    path: 'petugas-rm',
    loadChildren: () => import('./medical-record/petugas-rm/petugas-rm.module').then((m) => m.PetugasRmPageModule),
  },
  {
    path: 'rm-to-clinic',
    loadChildren: () =>
      import('./medical-record/petugas-rm/rm-to-clinic/rm-to-clinic.module').then((m) => m.RmToClinicPageModule),
  },
  {
    path: 'manage-tracer-pasien',
    loadChildren: () =>
      import('./medical-record/petugas-rm/manage-tracer-pasien/manage-tracer-pasien.module').then(
        (m) => m.ManageTracerPasienPageModule
      ),
  },
  {
    path: 'absensi-karyawan',
    loadChildren: () => import('./absensi-karyawan/absensi-karyawan.module').then((m) => m.AbsensiKaryawanPageModule),
  },
  {
    path: 'absensi-karyawan/keluar',
    loadChildren: () =>
      import('./absensi-karyawan/absensi-keluar/absensi-keluar.module').then((m) => m.AbsensiKeluarPageModule),
  },
  {
    path: 'manage-tindakan',
    loadChildren: () =>
      import('./keuangan/manage-tindakan/manage-tindakan.module').then((m) => m.ManageTindakanPageModule),
  },
  {
    path: 'screening-covid-ladokgi',
    loadChildren: () =>
      import('./pegawai/screening-covid-ladokgi/screening-covid-ladokgi.module').then(
        (m) => m.ScreeningCovidLadokgiPageModule
      ),
  },
  {
    path: 'goods-receiving',
    loadChildren: () =>
      import('./logistik/goods-receiving/goods-receiving.module').then((m) => m.GoodsReceivingPageModule),
  },
  {
    path: 'curriculum-vitae',
    loadChildren: () => import('./curriculum-vitae/curriculum-vitae.module').then((m) => m.CurriculumVitaePageModule),
  },
  {
    path: 'manage-item',
    loadChildren: () => import('./logistik/manage-item/manage-item.module').then((m) => m.ManageItemPageModule),
  },
  {
    path: 'diagnosa-awal',
    loadChildren: () => import('./medis/diagnosa-awal/diagnosa-awal.module').then((m) => m.DiagnosaAwalPageModule),
  },
  {
    path: 'pendaftaran-pasien-baru-online',
    loadChildren: () =>
      import('./medical-record/pendaftaran-pasien-baru-online/pendaftaran-pasien-baru-online.module').then(
        (m) => m.PendaftaranPasienBaruOnlinePageModule
      ),
  },
  {
    path: 'debt-list',
    loadChildren: () => import('./kasir/debt-list/debt-list.module').then((m) => m.DebtListPageModule),
  },
  {
    path: 'manage-cetak-kasir',
    loadChildren: () =>
      import('./kasir/manage-cetak-kasir/manage-cetak-kasir.module').then((m) => m.ManageCetakKasirPageModule),
  },
  {
    path: 'pendaftaran-rawat-jalan-online',
    loadChildren: () =>
      import('./medical-record/pendaftaran-rawat-jalan-online/pendaftaran-rawat-jalan-online.module').then(
        (m) => m.PendaftaranRawatJalanOnlinePageModule
      ),
  },
  {
    path: 'dashboard-kedatangan-pasien',
    loadChildren: () =>
      import('./dashboard-master/dashboard-kedatangan-pasien/dashboard-kedatangan-pasien.module').then(
        (m) => m.DashboardKedatanganPasienPageModule
      ),
  },
  {
    path: 'manage-jadwal-dokter',
    loadChildren: () =>
      import('./medical-record/manage-jadwal-dokter/manage-jadwal-dokter.module').then(
        (m) => m.ManageJadwalDokterPageModule
      ),
  },
  {
    path: 'dashboard-keuangan',
    loadChildren: () =>
      import('./dashboard-master/dashboard-keuangan/dashboard-keuangan.module').then(
        (m) => m.DashboardKeuanganPageModule
      ),
  },
  {
    path: 'kalender-jadwal-dokter',
    loadChildren: () =>
      import('./dashboard/kalender-jadwal-dokter/kalender-jadwal-dokter.module').then(
        (m) => m.KalenderJadwalDokterPageModule
      ),
  },
  {
    path: 'manage-kupon',
    loadChildren: () => import('./marketing/manage-kupon/manage-kupon.module').then((m) => m.ManageKuponPageModule),
  },
  {
    path: 'manage-kupon-pembayaran',
    loadChildren: () =>
      import('./marketing/manage-kupon-pembayaran/manage-kupon-pembayaran.module').then(
        (m) => m.ManageKuponPembayaranPageModule
      ),
  },
  {
    path: 'resep-digital',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/resep-digital/resep-digital.module').then(
        (m) => m.ResepDigitalPageModule
      ),
  },
  {
    path: 'manage-keyword-obat',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/manage-keyword-obat/manage-keyword-obat.module').then(
        (m) => m.ManageKeywordObatPageModule
      ),
  },
  {
    path: 'resep-racikan-digital',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/resep-racikan-digital/resep-racikan-digital.module').then(
        (m) => m.ResepRacikanDigitalPageModule
      ),
  },
  {
    path: 'antrian-konsulen',
    loadChildren: () =>
      import('./medis/antrian-konsulen/antrian-konsulen.module').then((m) => m.AntrianKonsulenPageModule),
  },
  {
    path: 'ubah-cppt',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/ubah-cppt/ubah-cppt.module').then((m) => m.UbahCpptPageModule),
  },
  {
    path: 'pengeluaran-gudang',
    loadChildren: () =>
      import('./logistik/pengeluaran-gudang/pengeluaran-gudang.module').then((m) => m.PengeluaranGudangPageModule),
  },
  {
    path: 'manage-tarif-tindakan',
    loadChildren: () =>
      import('./keuangan/manage-tarif-tindakan/manage-tarif-tindakan.module').then(
        (m) => m.ManageTarifTindakanPageModule
      ),
  },
  {
    path: 'manage-asset-management',
    loadChildren: () =>
      import('./keuangan/manage-asset-management/manage-asset-management.module').then(
        (m) => m.ManageAssetManagementPageModule
      ),
  },
  {
    path: 'antrian-resep-digital',
    loadChildren: () =>
      import('./medis/antrian-resep-digital/antrian-resep-digital.module').then((m) => m.AntrianResepDigitalPageModule),
  },
  {
    path: 'help-desk',
    loadChildren: () => import('./sarana-prasarana/help-desk/help-desk.module').then((m) => m.HelpDeskPageModule),
  },
  {
    path: 'tindak-lanjut-help-desk',
    loadChildren: () =>
      import('./sarana-prasarana/tindak-lanjut-help-desk/tindak-lanjut-help-desk.module').then(
        (m) => m.TindakLanjutHelpDeskPageModule
      ),
  },
  {
    path: 'laporan-help-desk',
    loadChildren: () =>
      import('./sarana-prasarana/laporan-help-desk/laporan-help-desk.module').then((m) => m.LaporanHelpDeskPageModule),
  },
  {
    path: 'manage-login',
    loadChildren: () =>
      import('./clinic-settings/manage-login/manage-login.module').then((m) => m.ManageLoginPageModule),
  },
  {
    path: 'riwayat-resep-digital',
    loadChildren: () =>
      import('./farmasi/riwayat-resep-digital/riwayat-resep-digital.module').then(
        (m) => m.RiwayatResepDigitalPageModule
      ),
  },
  {
    path: 'jadwal-kunjungan-berikutnya/:idPasien',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/jadwal-kunjungan-berikutnya/jadwal-kunjungan-berikutnya.module').then(
        (m) => m.JadwalKunjunganBerikutnyaPageModule
      ),
  },
  {
    path: 'voice-call/:chatRoomId',
    loadChildren: () => import('./voice-call/voice-call.module').then((m) => m.VoiceCallPageModule),
  },
  {
    path: 'manage-pegawai',
    loadChildren: () => import('./pegawai/manage-pegawai/manage-pegawai.module').then((m) => m.ManagePegawaiPageModule),
  },
  {
    path: 'tulis-resep-digital',
    loadChildren: () =>
      import('./farmasi/tulis-resep-digital/tulis-resep-digital.module').then((m) => m.TulisResepDigitalPageModule),
  },
  {
    path: 'chat-finder',
    loadChildren: () => import('./chat-finder/chat-finder.module').then((m) => m.ChatFinderPageModule),
  },
  {
    path: 'surat-masuk',
    loadChildren: () => import('./e-office/surat-masuk/surat-masuk.module').then((m) => m.SuratMasukPageModule),
  },
  {
    path: 'surat-keluar',
    loadChildren: () => import('./e-office/surat-keluar/surat-keluar.module').then((m) => m.SuratKeluarPageModule),
  },
  {
    path: 'kategori-eoffice',
    loadChildren: () =>
      import('./e-office/kategori-eoffice/kategori-eoffice.module').then((m) => m.KategoriEofficePageModule),
  },
  {
    path: 'master-installasi',
    loadChildren: () =>
      import('./master/master-installasi/master-installasi.module').then((m) => m.MasterInstallasiPageModule),
  },
  {
    path: 'master-poli-klinik',
    loadChildren: () =>
      import('./master/master-poli-klinik/master-poli-klinik.module').then((m) => m.MasterPoliKlinikPageModule),
  },
  {
    path: 'master-gudang',
    loadChildren: () => import('./master/master-gudang/master-gudang.module').then((m) => m.MasterGudangPageModule),
  },
  {
    path: 'master-rak',
    loadChildren: () => import('./master/master-rak/master-rak.module').then((m) => m.MasterRakPageModule),
  },
  {
    path: 'master-supplier',
    loadChildren: () =>
      import('./master/master-supplier/master-supplier.module').then((m) => m.MasterSupplierPageModule),
  },
  {
    path: 'master-cuti',
    loadChildren: () => import('./master/master-cuti/master-cuti.module').then((m) => m.MasterCutiPageModule),
  },
  {
    path: 'master-tarif-odontogram',
    loadChildren: () =>
      import('./master/master-tarif-odontogram/master-tarif-odontogram.module').then(
        (m) => m.MasterTarifOdontogramPageModule
      ),
  },
  {
    path: 'jenis-file',
    loadChildren: () => import('./e-office/jenis-file/jenis-file.module').then((m) => m.JenisFilePageModule),
  },
  {
    path: 'file',
    loadChildren: () => import('./e-office/file/file.module').then((m) => m.FilePageModule),
  },
  {
    path: 'asesmen-bedah-minor',
    loadChildren: () =>
      import('./formulir-medis/asesmen-bedah-minor/asesmen-bedah-minor.module').then(
        (m) => m.AsesmenBedahMinorPageModule
      ),
  },
  {
    path: 'permohonan-cuti',
    loadChildren: () => import('./permohonan-cuti/permohonan-cuti.module').then((m) => m.PermohonanCutiPageModule),
  },
  {
    path: 'approval-surat-cuti',
    loadChildren: () =>
      import('./pegawai/approval-surat-cuti/approval-surat-cuti.module').then((m) => m.ApprovalSuratCutiPageModule),
  },
  {
    path: 'transfer-ruang-inap',
    loadChildren: () =>
      import('./medis/transfer-ruang-inap/transfer-ruang-inap.module').then((m) => m.TransferRuangInapPageModule),
  },
  {
    path: 'chat-rooms',
    loadChildren: () => import('./chat-rooms/chat-rooms.module').then((m) => m.ChatRoomsPageModule),
  },
  {
    path: 'rawat-bersama',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/rawat-bersama/rawat-bersama.module').then(
        (m) => m.RawatBersamaPageModule
      ),
  },
  {
    path: 'pindah-dokter',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/pindah-dokter/pindah-dokter.module').then(
        (m) => m.PindahDokterPageModule
      ),
  },
  {
    path: 'surat-pulang-rawat-inap',
    loadChildren: () =>
      import(
        './medis/antrian-pasien-ranap/pasien-rawat-inap/surat-pulang-rawat-inap/surat-pulang-rawat-inap.module'
      ).then((m) => m.SuratPulangRawatInapPageModule),
  },
  {
    path: 'tagihan-pasien',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/tagihan-pasien/tagihan-pasien.module').then(
        (m) => m.TagihanPasienPageModule
      ),
  },
  {
    path: 'pasien-boleh-pulang',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/pasien-boleh-pulang/pasien-boleh-pulang.module').then(
        (m) => m.PasienBolehPulangPageModule
      ),
  },
  {
    path: 'pasien-pulang',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/pasien-pulang/pasien-pulang.module').then(
        (m) => m.PasienPulangPageModule
      ),
  },
  {
    path: 'tulis-resep-digital-rawat-inap',
    loadChildren: () =>
      import('./farmasi/tulis-resep-digital-rawat-inap/tulis-resep-digital-rawat-inap.module').then(
        (m) => m.TulisResepDigitalRawatInapPageModule
      ),
  },
  {
    path: 'antrian-resep-digital-rawat-inap',
    loadChildren: () =>
      import('./farmasi/antrian-resep-digital-rawat-inap/antrian-resep-digital-rawat-inap.module').then(
        (m) => m.AntrianResepDigitalRawatInapPageModule
      ),
  },
  {
    path: 'riwayat-resep-digital-rawat-inap',
    loadChildren: () =>
      import('./farmasi/riwayat-resep-digital-rawat-inap/riwayat-resep-digital-rawat-inap.module').then(
        (m) => m.RiwayatResepDigitalRawatInapPageModule
      ),
  },
  {
    path: 'manage-coa',
    loadChildren: () => import('./keuangan/manage-coa/manage-coa.module').then((m) => m.ManageCoaPageModule),
  },
  {
    path: 'ubah-tanggal-kunjungan',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-tanggal-kunjungan/ubah-tanggal-kunjungan.module').then(
        (m) => m.UbahTanggalKunjunganPageModule
      ),
  },
  {
    path: 'history-adime',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/history-adime/history-adime.module').then(
        (m) => m.HistoryAdimePageModule
      ),
  },
  {
    path: 'history-asesmen-nyeri',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/history-asesmen-nyeri/history-asesmen-nyeri.module').then(
        (m) => m.HistoryAsesmenNyeriPageModule
      ),
  },
  {
    path: 'diagnosa-ukgs',
    loadChildren: () => import('./medis/diagnosa-ukgs/diagnosa-ukgs.module').then((m) => m.DiagnosaUkgsPageModule),
  },
  {
    path: 'history-icd-gigi',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/history-icd-gigi/history-icd-gigi.module').then(
        (m) => m.HistoryIcdGigiPageModule
      ),
  },
  {
    path: 'manage-delete-tracer-pasien',
    loadChildren: () =>
      import('./medical-record/manage-delete-tracer-pasien/manage-delete-tracer-pasien.module').then(
        (m) => m.ManageDeleteTracerPasienPageModule
      ),
  },
  {
    path: 'jadwal-operasi',
    loadChildren: () =>
      import('./dashboard/jadwal-operasi/jadwal-operasi.module').then((m) => m.JadwalOperasiPageModule),
  },
  {
    path: 'need-confirmation-appointment',
    loadChildren: () =>
      import(
        './dashboard/need-confirmation-appointment/need-confirmation-appointment/need-confirmation-appointment.module'
      ).then((m) => m.NeedConfirmationAppointmentPageModule),
  },
  {
    path: 'master-unit-kerja',
    loadChildren: () =>
      import('./master/master-unit-kerja/master-unit-kerja.module').then((m) => m.MasterUnitKerjaPageModule),
  },
  {
    path: 'master-jabatan',
    loadChildren: () => import('./master/master-jabatan/master-jabatan.module').then((m) => m.MasterJabatanPageModule),
  },
  {
    path: 'non-pasien',
    loadChildren: () => import('./medical-record/non-pasien/non-pasien.module').then((m) => m.NonPasienPageModule),
  },
  {
    path: 'koding-icd',
    loadChildren: () => import('./medical-record/koding-icd/koding-icd.module').then((m) => m.KodingIcdPageModule),
  },
  {
    path: 'paket-multiple-tindakan',
    loadChildren: () =>
      import('./keuangan/paket-multiple-tindakan/paket-multiple-tindakan.module').then(
        (m) => m.PaketMultipleTindakanPageModule
      ),
  },
  {
    path: 'resume-diagnosa',
    loadChildren: () => import('./rata/resume-diagnosa/resume-diagnosa.module').then((m) => m.ResumeDiagnosaPageModule),
  },
  {
    path: 'resume',
    loadChildren: () => import('./rata/resume/resume.module').then((m) => m.ResumePageModule),
  },
  {
    path: 'slip-treatment',
    loadChildren: () => import('./rata/slip-treatment/slip-treatment.module').then((m) => m.SlipTreatmentPageModule),
  },
  {
    path: 'adjustment-stok',
    loadChildren: () =>
      import('./logistik/adjustment-stok/adjustment-stok.module').then((m) => m.AdjustmentStokPageModule),
  },
  {
    path: 'manage-surat-pasien-pulang',
    loadChildren: () =>
      import('./medical-record/manage-surat-pasien-pulang/manage-surat-pasien-pulang.module').then(
        (m) => m.ManageSuratPasienPulangPageModule
      ),
  },
  {
    path: 'approval-unit-kerja',
    loadChildren: () =>
      import('./pegawai/approval-unit-kerja/approval-unit-kerja.module').then((m) => m.ApprovalUnitKerjaPageModule),
  },
  {
    path: 'master-metode-pembayaran',
    loadChildren: () =>
      import('./master/master-metode-pembayaran/master-metode-pembayaran.module').then(
        (m) => m.MasterMetodePembayaranPageModule
      ),
  },
  // {
  //   path: 'foto-pasien',
  //   loadChildren: () => import('./rata/foto-pasien/foto-pasien.module').then((m) => m.FotoPasienPageModule),
  // },
  {
    path: 'foto-pasien-before-after',
    loadChildren: () =>
      import('./rata/foto-pasien-before-after/foto-pasien-before-after.module').then(
        (m) => m.FotoPasienBeforeAfterPageModule
      ),
  },
  {
    path: 'drawing-odontogram',
    loadChildren: () =>
      import('./medis/drawing-odontogram/drawing-odontogram.module').then((m) => m.DrawingOdontogramPageModule),
  },
  {
    path: 'manage-kategori-maintenance',
    loadChildren: () =>
      import('./sarana-prasarana/manage-kategori-maintenance/manage-kategori-maintenance.module').then(
        (m) => m.ManageKategoriMaintenancePageModule
      ),
  },
  {
    path: 'anamnesis',
    loadChildren: () => import('./rata/anamnesis/anamnesis.module').then((m) => m.AnamnesisPageModule),
  },
  {
    path: 'aligner',
    loadChildren: () => import('./rata/aligner/aligner.module').then((m) => m.AlignerPageModule),
  },
  {
    path: 'extra-aligner',
    loadChildren: () => import('./rata/extra-aligner/extra-aligner.module').then((m) => m.ExtraAlignerPageModule),
  },
  {
    path: 'retainer-rata',
    loadChildren: () => import('./rata/retainer-rata/retainer-rata.module').then((m) => m.RetainerRataPageModule),
  },
  {
    path: 'first-set-visit',
    loadChildren: () => import('./rata/first-set-visit/first-set-visit.module').then((m) => m.FirstSetVisitPageModule),
  },
  {
    path: 'cetak-ulang',
    loadChildren: () => import('./rata/cetak-ulang/cetak-ulang.module').then((m) => m.CetakUlangPageModule),
  },
  {
    path: 'refinement',
    loadChildren: () => import('./rata/refinement/refinement.module').then((m) => m.RefinementPageModule),
  },
  {
    path: 'summary',
    loadChildren: () => import('./rata/summary/summary.module').then((m) => m.SummaryPageModule),
  },
  {
    path: 'activity',
    loadChildren: () => import('./rata/activity/activity.module').then((m) => m.ActivityPageModule),
  },
  {
    path: 'laporan-keuangan-pasien-belum-bayar',
    loadChildren: () =>
      import('./laporan-keuangan/laporan-keuangan-pasien-belum-bayar/laporan-keuangan-pasien-belum-bayar.module').then(
        (m) => m.LaporanKeuanganPasienBelumBayarPageModule
      ),
  },
  {
    path: 'bridge-medis-rata',
    loadChildren: () =>
      import('./rata/bridge-medis-rata/bridge-medis-rata.module').then((m) => m.BridgeMedisRataPageModule),
  },
  {
    path: 'icd-ten-rata',
    loadChildren: () => import('./rata/icd-ten-rata/icd-ten-rata.module').then((m) => m.IcdTenRataPageModule),
  },
  {
    path: 'manage-purchase-retur',
    loadChildren: () =>
      import('./logistik/manage-purchase-retur/manage-purchase-retur.module').then(
        (m) => m.ManagePurchaseReturPageModule
      ),
  },
  {
    path: 'manage-cetak-kasir-online',
    loadChildren: () =>
      import('./kasir/manage-cetak-kasir-online/manage-cetak-kasir-online.module').then(
        (m) => m.ManageCetakKasirOnlinePageModule
      ),
  },
  {
    path: 'embedded-window',
    loadChildren: () => import('./embedded-window/embedded-window.module').then((m) => m.EmbeddedWindowPageModule),
  },
  {
    path: 'manage-jam-kerja',
    loadChildren: () =>
      import('./pegawai/manage-jam-kerja/manage-jam-kerja.module').then((m) => m.ManageJamKerjaPageModule),
  },
  {
    path: 'manage-batal-kwitansi',
    loadChildren: () =>
      import('./kasir/manage-batal-kwitansi/manage-batal-kwitansi.module').then((m) => m.ManageBatalKwitansiPageModule),
  },
  {
    path: 'manage-batal-kasir-pasien-mobile',
    loadChildren: () =>
      import('./kasir/manage-batal-kasir-pasien-mobile/manage-batal-kasir-pasien-mobile.module').then(
        (m) => m.ManageBatalKasirPasienMobilePageModule
      ),
  },
  {
    path: 'manage-pindah-nomor-rekam-medis',
    loadChildren: () =>
      import('./medical-record/manage-pindah-nomor-rekam-medis/manage-pindah-nomor-rekam-medis.module').then(
        (m) => m.ManagePindahNomorRekamMedisPageModule
      ),
  },
  {
    path: 'bridge-medis-gigiid',
    loadChildren: () =>
      import('./gigiid/bridge-medis-gigiid/bridge-medis-gigiid.module').then((m) => m.BridgeMedisGigiidPageModule),
  },
  {
    path: 'form-medis-gigiid',
    loadChildren: () => import('./gigiid/form-medis/form-medis.module').then((m) => m.FormMedisPageModule),
  },
  {
    path: 'history-form-medis-gigiid',
    loadChildren: () =>
      import('./gigiid/history-form-medis/history-form-medis.module').then((m) => m.HistoryFormMedisPageModule),
  },
  {
    path: 'riwayat-absensi',
    loadChildren: () =>
      import('./absensi-karyawan/riwayat-absensi/riwayat-absensi.module').then((m) => m.RiwayatAbsensiPageModule),
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./logout/update-profile/update-profile.module').then((m) => m.UpdateProfilePageModule),
  },
  {
    path: 'drawing-odontogram-ukgs',
    loadChildren: () =>
      import('./medis/drawing-odontogram-ukgs/drawing-odontogram-ukgs.module').then(
        (m) => m.DrawingOdontogramUkgsPageModule
      ),
  },
  {
    path: 'medical-record/jadwal-kunjungan-berikutnya',
    loadChildren: () =>
      import('./medical-record/jadwal-kunjungan-berikutnya/jadwal-kunjungan-berikutnya.module').then(
        (m) => m.JadwalKunjunganBerikutnyaPageModule
      ),
  },
  {
    path: 'master-cuti-disable',
    loadChildren: () =>
      import('./master/master-cuti-disable/master-cuti-disable.module').then((m) => m.MasterCutiDisablePageModule),
  },
  {
    path: 'setting-cuti',
    loadChildren: () =>
      import('./clinic-settings/setting-cuti/setting-cuti.module').then((m) => m.SettingCutiPageModule),
  },
  {
    path: 'manage-sharing-fee',
    loadChildren: () =>
      import('./keuangan/manage-sharing-fee/manage-sharing-fee.module').then((m) => m.ManageSharingFeePageModule),
  },
  {
    path: 'manage-sharing-fee-produk',
    loadChildren: () =>
      import('./keuangan/manage-sharing-fee-produk/manage-sharing-fee-produk.module').then(
        (m) => m.ManageSharingFeeProdukModule
      ),
  },
  {
    path: 'surat-sakit',
    loadChildren: () => import('./surat-medis/surat-sakit/surat-sakit.module').then((m) => m.SuratSakitPageModule),
  },
  {
    path: 'surat-sehat',
    loadChildren: () => import('./surat-medis/surat-sehat/surat-sehat.module').then((m) => m.SuratSehatPageModule),
  },
  {
    path: 'manage-tindakan-online',
    loadChildren: () =>
      import('./keuangan/manage-tindakan-online/manage-tindakan-online.module').then(
        (m) => m.ManageTindakanOnlinePageModule
      ),
  },
  {
    path: 'transaksi-pasien/:idPasien',
    loadChildren: () =>
      import('./medical-record/pasien/data-pasien/transaksi-pasien/transaksi-pasien.module').then(
        (m) => m.TransaksiPasienPageModule
      ),
  },
  {
    path: 'master-banner',
    loadChildren: () => import('./master/master-banner/master-banner.module').then((m) => m.MasterBannerPageModule),
  },
  {
    path: 'master-miscafac',
    loadChildren: () =>
      import('./master/master-miscafac/master-miscafac.module').then((m) => m.MasterMiscafacPageModule),
  },
  {
    path: 'master-signa',
    loadChildren: () => import('./master/master-signa/master-signa.module').then((m) => m.MasterSignaPageModule),
  },
  {
    path: 'master-event',
    loadChildren: () => import('./master/master-event/master-event.module').then((m) => m.MasterEventPageModule),
  },
  {
    path: 'manage-pembayaran-ap',
    loadChildren: () =>
      import('./keuangan/manage-pembayaran-ap/manage-pembayaran-ap.module').then((m) => m.ManagePembayaranApPageModule),
  },
  {
    path: 'master-biaya-tambahan-kasir',
    loadChildren: () =>
      import('./master/master-biaya-tambahan-kasir/master-biaya-tambahan-kasir.module').then(
        (m) => m.MasterBiayaTambahanKasirPageModule
      ),
  },
  {
    path: 'cppt-form',
    loadChildren: () => import('./trisakti/form-medis/cppt-form/cppt-form.module').then((m) => m.CpptFormPageModule),
  },
  {
    path: 'rencana-tindakan-form',
    loadChildren: () =>
      import('./trisakti/form-medis/rencana-tindakan-form/rencana-tindakan-form.module').then(
        (m) => m.RencanaTindakanFormPageModule
      ),
  },
  {
    path: 'penerimaan-gudang',
    loadChildren: () =>
      import('./logistik/penerimaan-gudang/penerimaan-gudang.module').then((m) => m.PenerimaanGudangPageModule),
  },
  {
    path: 'manage-pembayaran-op',
    loadChildren: () =>
      import('./keuangan/manage-pembayaran-op/manage-pembayaran-op.module').then((m) => m.ManagePembayaranOpPageModule),
  },
  {
    path: 'manage-pemasukan-ar',
    loadChildren: () =>
      import('./keuangan/manage-pemasukan-ar/manage-pemasukan-ar.module').then((m) => m.ManagePemasukanArPageModule),
  },
  {
    path: 'manage-pengeluaran-gudang',
    loadChildren: () =>
      import('./logistik/manage-pengeluaran-gudang/manage-pengeluaran-gudang.module').then(
        (m) => m.ManagePengeluaranGudangModule
      ),
  },
  {
    path: 'retur-permintaan-gudang',
    loadChildren: () =>
      import('./logistik/retur-permintaan-gudang/retur-permintaan-gudang.module').then(
        (m) => m.ReturPermintaanGudangPageModule
      ),
  },
  {
    path: 'bridge-medis-new',
    loadChildren: () =>
      import('./medis/bridge-medis-new/bridge-medis-new.module').then((m) => m.BridgeMedisNewPageModule),
  },
  {
    path: 'form-medis',
    loadChildren: () => import('./medis/form-medis/form-medis.module').then((m) => m.FormMedisPageModule),
  },
  {
    path: 'form-medis/:tag',
    loadChildren: () => import('./medis/form-medis/form-medis.module').then((m) => m.FormMedisPageModule),
  },
  {
    path: 'manage-tindakan-nonaktif',
    loadChildren: () =>
      import('./keuangan/manage-tindakan-nonaktif/manage-tindakan-nonaktif.module').then(
        (m) => m.ManageTindakanNonaktifPageModule
      ),
  },
  {
    path: 'manage-batal-kunjungan',
    loadChildren: () =>
      import('./medical-record/manage-batal-kunjungan/manage-batal-kunjungan.module').then(
        (m) => m.ManageBatalKunjunganPageModule
      ),
  },
  {
    path: 'manage-expense',
    loadChildren: () => import('./keuangan/manage-expense/manage-expense.module').then((m) => m.ManageExpenseModule),
  },
  {
    path: 'manage-pegawai-nonaktif',
    loadChildren: () =>
      import('./pegawai/manage-pegawai-nonaktif/manage-pegawai-nonaktif.module').then(
        (m) => m.ManagePegawaiNonaktifPageModule
      ),
  },
  {
    path: 'manage-item-nonaktif',
    loadChildren: () =>
      import('./logistik/manage-item-nonaktif/manage-item-nonaktif.module').then((m) => m.ManageItemNonaktifPageModule),
  },
  {
    path: 'manage-item-v2',
    loadChildren: () => import('./logistik/manage-item-v2/manage-item-v2.module').then((m) => m.ManageItemV2PageModule),
  },
  {
    path: 'pos-v2',
    loadChildren: () => import('./pos-v2/pos-v2.module').then((m) => m.PosV2PageModule),
  },
  {
    path: 'sign-up-v2',
    loadChildren: () => import('./sign-up-v2/sign-up-v2.module').then((m) => m.SignUpV2PageModule),
  },
  {
    path: 'formulir',
    loadChildren: () => import('./formulir/formulir.module').then((m) => m.FormulirComponentModule),
  },
  {
    path: 'ubah-hak-kelas',
    loadChildren: () =>
      import('./medis/antrian-pasien-ranap/pasien-rawat-inap/ubah-hak-kelas/ubah-hak-kelas.module').then(
        (m) => m.UbahHakKelasPageModule
      ),
  },
  {
    path: 'fake-gps-testing',
    loadChildren: () => import('./fake-gps-testing/fake-gps-testing.module').then((m) => m.FakeGpsTestingPageModule),
  },
  {
    path: 'detail-pembayaran-op/:id',
    loadChildren: () =>
      import('./keuangan/manage-pembayaran-op/detail-pembayaran-op/detail-pembayaran-op.module').then(
        (m) => m.DetailPembayaranOpPageModule
      ),
  },
  {
    path: 'manage-batal-kwitansi',
    loadChildren: () =>
      import('./kasir/manage-batal-kwitansi/manage-batal-kwitansi.module').then((m) => m.ManageBatalKwitansiPageModule),
  },
  {
    path: 'riwayat-odontogram',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/riwayat-odontogram/riwayat-odontogram.module').then(
        (m) => m.RiwayatOdontogramPageModule
      ),
  },
  {
    path: 'history-kondisi-gigi',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/history-kondisi-gigi/history-kondisi-gigi.module').then(
        (m) => m.HistoryKondisiGigiPageModule
      ),
  },
  {
    path: 'retur-barang',
    loadChildren: () => import('./medis/retur-barang/retur-barang.module').then((m) => m.ReturBarangPageModule),
  },
  {
    path: 'detail-laporan-kedatangan-pasien/:pageType/:patientType',
    loadChildren: () =>
      import(
        './dashboard-master/dashboard-kedatangan-pasien/detail-laporan-kedatangan-pasien/detail-laporan-kedatangan-pasien.module'
      ).then((m) => m.DetailLaporanKedatanganPasienPageModule),
  },
  {
    path: 'detail-laporan-cash-flow',
    loadChildren: () =>
      import('./dashboard-master/dashboard-keuangan/detail-laporan-cash-flow/detail-laporan-cash-flow.module').then(
        (m) => m.DetailLaporanCashFlowPageModule
      ),
  },
  {
    path: 'detail-transaksi-ewallet/:pageType',
    loadChildren: () =>
      import('./keuangan/dashboard-ewallet/detail-transaksi-ewallet/detail-transaksi-ewallet.module').then(
        (m) => m.DetailTransaksiEwalletPageModule
      ),
  },
  {
    path: 'nusatrip',
    loadChildren: () => import('./dashboard/nusatrip/nusatrip.module').then((m) => m.NusatripPageModule),
  },
  {
    path: 'dashboard-ewallet',
    loadChildren: () =>
      import('./keuangan/dashboard-ewallet/dashboard-ewallet.module').then((m) => m.DashboardEwalletPageModule),
  },
  {
    path: 'master-tarif-odontogram',
    loadChildren: () =>
      import('./master/master-tarif-odontogram/master-tarif-odontogram.module').then(
        (m) => m.MasterTarifOdontogramPageModule
      ),
  },
  {
    path: 'customer-service',
    loadChildren: () =>
      import('./customer-service/customer-service/customer-service.module').then((m) => m.CustomerServicePageModule),
  },
  {
    path: 'history-chat/:userData',
    loadChildren: () =>
      import('./customer-service/history-chat/history-chat.module').then((m) => m.HistoryChatPageModule),
  },
  {
    path: 'cs-chat-room/:idRoom',
    loadChildren: () =>
      import('./customer-service/cs-chat-room/cs-chat-room.module').then((m) => m.CsChatRoomPageModule),
  },
  {
    path: 'cs-chat-room/:idRoom/:roomType',
    loadChildren: () =>
      import('./customer-service/cs-chat-room/cs-chat-room.module').then((m) => m.CsChatRoomPageModule),
  },
  {
    path: 'cs-detail-profile',
    loadChildren: () =>
      import('./customer-service/cs-detail-profile/cs-detail-profile.module').then((m) => m.CsDetailProfilePageModule),
  },
  {
    path: 'dashboard-whatsapp',
    loadChildren: () =>
      import('./cms/dashboard-whatsapp/dashboard-whatsapp.module').then((m) => m.DashboardWhatsappPageModule),
  },
  /// ================================================== Begin: Form Medis Routes ================================================== ///
  {
    path: 'detail-rekam-medis/:idLogStatus',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/rekam-medis/detail-rekam-medis/detail-rekam-medis.module').then(
        (m) => m.DetailRekamMedisPageModule
      ),
  },
  {
    path: 'asesmen-medis',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/asesmen-medis/asesmen-medis.module').then(
        (m) => m.AsesmenMedisPageModule
      ),
  },
  {
    path: 'asesmen-keperawatan',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/asesmen-keperawatan/asesmen-keperawatan.module').then(
        (m) => m.AsesmenKeperawatanPageModule
      ),
  },
  {
    path: 'rujukan',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/rujukan/rujukan.module').then((m) => m.RujukanPageModule),
  },
  {
    path: 'cppt',
    loadChildren: () => import('./medis/rawatjalan/pengisian-soap/cppt/cppt.module').then((m) => m.CpptPageModule),
  },
  {
    path: 'foto-pasien',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/foto-pasien/foto-pasien.module').then((m) => m.FotoPasienPageModule),
  },
  {
    path: 'file-pasien',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/file-pasien/file-pasien.module').then((m) => m.FilePasienPageModule),
  },

  {
    path: 'odontogram-gigi',
    loadChildren: () =>
      import('./gigiid/odontogram-gigi/odontogram-gigi.module').then((m) => m.OdontogramGigiPageModule),
  },
  {
    path: 'odontogram',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/odontogram/odontogram.module').then((m) => m.OdontogramPageModule),
  },
  {
    path: 'tindakan',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/tindakan/tindakan.module').then((m) => m.TindakanPageModule),
  },
  {
    path: 'form-resep-digital',
    loadChildren: () =>
      import('./farmasi/form-resep-digital/form-resep-digital.module').then((m) => m.FormResepDigitalPageModule),
  },
  {
    path: 'buat-janji',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/buat-janji-medis/buat-janji-medis.module').then(
        (m) => m.BuatJanjiMedisPageModule
      ),
  },
  {
    path: 'asesmen-koas',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/asesmen-koas/asesmen-koas.module').then((m) => m.AsesmenKoasPageModule),
  },
  {
    path: 'radiologi',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/radiologi/radiologi.module').then((m) => m.RadiologiPageModule),
  },
  {
    path: 'laboratorium',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/laboratorium/laboratorium.module').then(
        (m) => m.LaboratoriumPageModule
      ),
  },
  {
    path: 'rekam-medis-gigi',
    loadChildren: () =>
      import('./gigiid/rekam-medis-gigi/rekam-medis-gigi.module').then((m) => m.RekamMedisGigiPageModule),
  },
  /// ================================================== End: Form Medis Routes ================================================== ///
  {
    path: 'ubah-mahasiswa',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-mahasiswa/ubah-mahasiswa.module').then(
        (m) => m.UbahMahasiswaPageModule
      ),
  },
  {
    path: 'riwayat-gaji-absensi',
    loadChildren: () =>
      import('./logout/riwayat-gaji-absensi/riwayat-gaji-absensi.module').then((m) => m.RiwayatGajiAbsensiPageModule),
  },
  {
    path: 'ubah-bpjp',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-bpjp/ubah-bpjp.module').then((m) => m.UbahBpjpPageModule),
  },
  {
    path: 'manage-rujuk-klinik-external',
    loadChildren: () =>
      import('./medis/manage-rujuk-klinik-external/manage-rujuk-klinik-external.module').then(
        (m) => m.ManageRujukKlinikExternalPageModule
      ),
  },
  {
    path: 'manage-permohonan',
    loadChildren: () =>
      import('./pegawai/manage-permohonan-izin-pegawai/manage-permohonan-izin-pegawai.module').then(
        (m) => m.ManagePermohonanIzinPegawaiPageModule
      ),
  },
  {
    path: 'manage-permohonan-approval',
    loadChildren: () =>
      import('./pegawai/manage-approval-izin-pegawai/manage-approval-izin-pegawai.module').then(
        (m) => m.ManageApprovalIzinPegawaiPageModule
      ),
  },
  {
    path: 'generate-sep-ulang',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/generate-sep-ulang/generate-sep-ulang.module').then(
        (m) => m.GenerateSepUlangPageModule
      ),
  },
  {
    path: 'pcare-diagnosa',
    loadChildren: () => import('./Pcare/pcare-diagnosa/pcare-diagnosa.module').then((m) => m.PcareDiagnosaPageModule),
  },
  {
    path: 'pcare-dokter',
    loadChildren: () => import('./Pcare/pcare-dokter/pcare-dokter.module').then((m) => m.PcareDokterPageModule),
  },
  {
    path: 'pcare-kelompok',
    loadChildren: () => import('./Pcare/pcare-kelompok/pcare-kelompok.module').then((m) => m.PcareKelompokPageModule),
  },
  {
    path: 'odontogram-gigi',
    loadChildren: () =>
      import('./gigiid/odontogram-gigi/odontogram-gigi.module').then((m) => m.OdontogramGigiPageModule),
  },
  {
    path: 'odontogram',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/odontogram/odontogram.module').then((m) => m.OdontogramPageModule),
  },
  {
    path: 'tindakan',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/tindakan/tindakan.module').then((m) => m.TindakanPageModule),
  },
  {
    path: 'form-resep-digital',
    loadChildren: () =>
      import('./farmasi/form-resep-digital/form-resep-digital.module').then((m) => m.FormResepDigitalPageModule),
  },
  {
    path: 'buat-janji',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/buat-janji-medis/buat-janji-medis.module').then(
        (m) => m.BuatJanjiMedisPageModule
      ),
  },
  {
    path: 'asesmen-koas',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/asesmen-koas/asesmen-koas.module').then((m) => m.AsesmenKoasPageModule),
  },
  {
    path: 'radiologi',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/radiologi/radiologi.module').then((m) => m.RadiologiPageModule),
  },
  {
    path: 'laboratorium',
    loadChildren: () =>
      import('./medis/rawatjalan/pengisian-soap/laboratorium/laboratorium.module').then(
        (m) => m.LaboratoriumPageModule
      ),
  },
  {
    path: 'rekam-medis-gigi',
    loadChildren: () =>
      import('./gigiid/rekam-medis-gigi/rekam-medis-gigi.module').then((m) => m.RekamMedisGigiPageModule),
  },
  /// ================================================== End: Form Medis Routes ================================================== ///
  {
    path: 'ubah-mahasiswa',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-mahasiswa/ubah-mahasiswa.module').then(
        (m) => m.UbahMahasiswaPageModule
      ),
  },
  {
    path: 'riwayat-gaji-absensi',
    loadChildren: () =>
      import('./logout/riwayat-gaji-absensi/riwayat-gaji-absensi.module').then((m) => m.RiwayatGajiAbsensiPageModule),
  },
  {
    path: 'ubah-bpjp',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/ubah-bpjp/ubah-bpjp.module').then((m) => m.UbahBpjpPageModule),
  },
  {
    path: 'manage-rujuk-klinik-external',
    loadChildren: () =>
      import('./medis/manage-rujuk-klinik-external/manage-rujuk-klinik-external.module').then(
        (m) => m.ManageRujukKlinikExternalPageModule
      ),
  },
  {
    path: 'manage-permohonan-izin-pegawai',
    loadChildren: () =>
      import('./pegawai/manage-permohonan-izin-pegawai/manage-permohonan-izin-pegawai.module').then(
        (m) => m.ManagePermohonanIzinPegawaiPageModule
      ),
  },
  {
    path: 'manage-approval-izin-pegawai',
    loadChildren: () =>
      import('./pegawai/manage-approval-izin-pegawai/manage-approval-izin-pegawai.module').then(
        (m) => m.ManageApprovalIzinPegawaiPageModule
      ),
  },
  {
    path: 'generate-sep-ulang',
    loadChildren: () =>
      import('./medical-record/tracer-pasien/generate-sep-ulang/generate-sep-ulang.module').then(
        (m) => m.GenerateSepUlangPageModule
      ),
  },
  {
    path: 'rujuk-klinik-eksternal-gigi',
    loadChildren: () =>
      import('./gigiid/rujuk-klinik-eksternal-gigi/rujuk-klinik-eksternal-gigi.module').then(
        (m) => m.RujukKlinikEksternalGigiPageModule
      ),
  },
  {
    path: 'pcare-kesadaran',
    loadChildren: () =>
      import('./Pcare/pcare-kesadaran/pcare-kesadaran.module').then((m) => m.PcareKesadaranPageModule),
  },
  {
    path: 'pcare-kunjungan',
    loadChildren: () =>
      import('./Pcare/pcare-kunjungan/pcare-kunjungan.module').then((m) => m.PcareKunjunganPageModule),
  },
  {
    path: 'pcare-mcu',
    loadChildren: () => import('./Pcare/pcare-mcu/pcare-mcu.module').then((m) => m.PcareMcuPageModule),
  },
  {
    path: 'pcare-obat',
    loadChildren: () => import('./Pcare/pcare-obat/pcare-obat.module').then((m) => m.PcareObatPageModule),
  },
  {
    path: 'pcare-pendaftaran',
    loadChildren: () =>
      import('./Pcare/pcare-pendaftaran/pcare-pendaftaran.module').then((m) => m.PcarePendaftaranPageModule),
  },
  {
    path: 'pcare-peserta',
    loadChildren: () => import('./Pcare/pcare-peserta/pcare-peserta.module').then((m) => m.PcarePesertaPageModule),
  },
  {
    path: 'pcare-poli',
    loadChildren: () => import('./Pcare/pcare-poli/pcare-poli.module').then((m) => m.PcarePoliPageModule),
  },
  {
    path: 'pcare-provider',
    loadChildren: () => import('./Pcare/pcare-provider/pcare-provider.module').then((m) => m.PcareProviderPageModule),
  },
  {
    path: 'pcare-specialis',
    loadChildren: () =>
      import('./Pcare/pcare-specialis/pcare-specialis.module').then((m) => m.PcareSpecialisPageModule),
  },
  {
    path: 'pcare-status-pulang',
    loadChildren: () =>
      import('./Pcare/pcare-status-pulang/pcare-status-pulang.module').then((m) => m.PcareStatusPulangPageModule),
  },
  {
    path: 'pcare-tindakan',
    loadChildren: () => import('./Pcare/pcare-tindakan/pcare-tindakan.module').then((m) => m.PcareTindakanPageModule),
  },
  {
    path: 'pcare-manage-kunjungan',
    loadChildren: () =>
      import('./Pcare/manage-kunjungan/manage-kunjungan.module').then((m) => m.ManageKunjunganPageModule),
  },
  {
    path: 'pcare-manage-pendaftaran',
    loadChildren: () =>
      import('./Pcare/manage-pendaftaran/manage-pendaftaran.module').then((m) => m.ManagePendaftaranPageModule),
  },
  {
    path: 'pcare-manage-tindakan',
    loadChildren: () =>
      import('./Pcare/manage-tindakan/manage-tindakan.module').then((m) => m.ManageTindakanPageModule),
  },
  {
    path: 'pcare-manage-obat',
    loadChildren: () => import('./Pcare/manage-obat/manage-obat.module').then((m) => m.ManageObatPageModule),
  },
  {
    path: 'pcare-manage-mcu',
    loadChildren: () => import('./Pcare/manage-mcu/manage-mcu.module').then((m) => m.ManageMcuPageModule),
  },
  {
    path: 'add-permintaan-distribusi',
    loadChildren: () =>
      import('./logistik/permintaan-gudang/add-permintaan-distribusi/add-permintaan-distribusi.module').then(
        (m) => m.AddPermintaanDistribusiPageModule
      ),
  },
  {
    path: 'add-permintaan-semua-barang',
    loadChildren: () =>
      import('./logistik/permintaan-gudang/add-permintaan-semua-barang/add-permintaan-semua-barang.module').then(
        (m) => m.AddPermintaanSemuaBarangPageModule
      ),
  },
  {
    path: 'dashboard-kepegawaian',
    loadChildren: () =>
      import('./dashboard-master/dashboard-kepegawaian/dashboard-kepegawaian.module').then(
        (m) => m.DashboardKepegawaianPageModule
      ),
  },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./login-background/forget-password/forget-password.module').then((m) => m.ForgetPasswordPageModule),
  },
  {
    path: 'dashboard-rawat-inap',
    loadChildren: () =>
      import('./dashboard-master/dashboard-rawat-inap/dashboard-rawat-inap.module').then(
        (m) => m.DashboardRawatInapPageModule
      ),
  },
  {
    path: 'dashboard-farmasi',
    loadChildren: () =>
      import('./dashboard-master/dashboard-farmasi/dashboard-farmasi.module').then((m) => m.DashboardFarmasiPageModule),
  },
  {
    path: 'manage-penilaian-kinerja-pegawai',
    loadChildren: () =>
      import('./pegawai/manage-penilaian-kinerja-pegawai/manage-penilaian-kinerja-pegawai.module').then(
        (m) => m.ManagePenilaianKinerjaPegawaiPageModule
      ),
  },
  {
    path: 'kalender-cuti-pegawai',
    loadChildren: () =>
      import('./pegawai/kalender-cuti-pegawai/kalender-cuti-pegawai.module').then(
        (m) => m.KalenderCutiPegawaiPageModule
      ),
  },
  {
    path: 'reward',
    loadChildren: () => import('./reward/reward.module').then((m) => m.RewardPageModule),
  },
  {
    path: 'manage-log-satusehat',
    loadChildren: () =>
      import('./satu-sehat/manage-log-satusehat/manage-log-satusehat.module').then(
        (m) => m.ManageLogSatusehatPageModule
      ),
  },
  {
    path: 'asesmen-medis-gigi',
    loadChildren: () =>
      import('./gigiid/asesmen-medis-gigi/asesmen-medis-gigi.module').then((m) => m.AsesmenMedisGigiPageModule),
  },
  {
    path: 'reimbursement',
    loadChildren: () => import('./reimbursement/reimbursement.module').then((m) => m.ReimbursementPageModule),
  },
  {
    path: 'approval-permohonan-reimburse',
    loadChildren: () =>
      import('./reimbursement/approval-permohonan-reimburse/approval-permohonan-reimburse.module').then(
        (m) => m.ApprovalPermohonanReimbursePageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
